import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About us',
    component: () => import('../views/About.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact us',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/managing-committee',
    name: 'Managing Committee',
    component: () => import('../views/ManagingCommittee.vue')
  },
  {
    path: '/chairman-message',
    name: 'Chairman Message',
    component: () => import('../views/ChairmanMessage.vue')
  },
  {
    path: '/vision-mission',
    name: 'VisionMission',
    component: () => import('../views/VisionMission.vue')
  },
  {
    path: '/degree',
    name: 'Degree',
    component: () => import('../views/Degree.vue')
  },
  {
    path: '/diploma',
    name: 'Diploma',
    component: () => import('../views/Diploma.vue')
  },
  {
    path: '/admissions',
    name: 'Admissions',
    component: () => import('../views/Admissions.vue')
  },
  {
    path: '/admissions-b-pharm',
    name: 'Admissions',
    component: () => import('../views/AdmissionsBPharma')
  },
  {
    path: '/admissions-d-pharm',
    name: 'Admissions',
    component: () => import('../views/AdmissionsDPharma')
  },
  {
    path: '/placements',
    name: 'Placements',
    component: () => import('../views/Placements.vue')
  },
  {
    path: '/campus-life',
    name: 'Campus Life',
    component: () => import('../views/CampusLife.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/fra',
    name: 'FRA',
    component: () => import('../views/Fra.vue')
  },
  {
    path: '/ytip-naac',
    name: 'NAAC',
    component: () => import('../views/Naac.vue')
  },
  {
    path: '/faculty',
    name: 'Faculty',
    component: () => import('../components/Faculty.vue')
  },
  {
    path: '/student-grievance',
    name: 'StudentGrievance',
    component: () => import('../views/StudentGrievance.vue')
  },
  {
    path: '/academics',
    name: 'Academics',
    component: () => import('../views/Academics.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  next()
})

export default router

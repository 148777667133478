<template>
  <section v-if="testimonials.length > 0">
    <div class="container">
      <!--Heading 1 Start-->
      <div class="iq_heading_1 text-center">
        <h4>former student <span> testimonial</span></h4>
        <p></p>
      </div>
      <!--Heading 1 End-->
      <!--Testimonial Slider Start-->
      <div class="testimonial-slider">
        <div v-for="(testimonial, index) in testimonials" :key="index">
          <!--Testimonial Start-->
          <div class="testimonial">
            <p>“ {{ testimonial.message }} ”</p>
            <div class="coures_instractor_thumb">
              <figure>
                <img :src="testimonial.image" :alt="testimonial.name">
              </figure>
              <div class="thumb_capstion">
                <h5><a href="#">{{ testimonial.name }}</a></h5>
                <p>{{ testimonial.subTitle }}</p>
              </div>
            </div>
          </div>
          <!--Testimonial End-->
        </div>
      </div>
      <!--Testimonial Slider End-->
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Testimonial',
  data () {
    return {
      // testimonials: [
      //   {
      //     name: 'Jonatahan James',
      //     subTitle: 'Former Student',
      //     message: 'Education WP Theme is a comprehensive LMS solution for WordPress Theme. This beautiful theme based on LearnPress - the best WordPress LMS plugin. Education WP theme will bring you the best LMS experience ever with super friendly UX and complete eLearning features.',
      //     image: 'extra-images/testimonial-nav2.jpg'
      //   }
      // ]
    }
  },
  computed: {
    ...mapState(['testimonials'])
  }
}
</script>

<style scoped>

</style>

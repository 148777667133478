<template>
  <!--Banner Wrap Start-->
  <div class="iq_banner">
    <ul class="slider">
      <li class="" v-for="(banner, index) in banners" :key="index">
        <!--Banner Thumb START-->
        <router-link :to="banner.link">
          <img :src="banner.image" alt="Image here">
          <div class="iq-caption-wrapper" :class="banner.class">
            <div class="iq_banner_caption container">
              <div class="iq-caption">
                <div class="iq-caption-title">{{ banner.title }}</div>
                <div class="iq-caption-text">{{ banner.text }}</div>
                <div class="iq-caption-contant">{{ banner.content }}</div>
                <router-link :to="banner.readMoreLink" v-if="banner.showReadMore" class="iq_link_1">Read More</router-link>
              </div>
            </div>
          </div>
        </router-link>
        <!--Banner Thumb End-->
      </li>
    </ul>
  </div>
  <!--Banner Wrap End-->
</template>

<script>

export default {
  name: 'Banners',
  data () {
    return {
      banners: [
        {
          link: '/about-us',
          image: 'https://i.ibb.co/MVJmqkP/Banner1-1.png',
          title: 'The Best Learning Institution',
          text: 'Welcome to Yadavrao Tasgaonkar Institute of Pharmacy',
          content: '',
          showReadMore: true,
          readMoreLink: '/about-us',
          class: 'text-left'
        },
        {
          link: '/degree',
          image: 'https://i.ibb.co/02dP43w/Banner1-2.png',
          title: 'The Best Learning Institution',
          text: 'YADAVRAO TASGAONKAR INSTITUTE OF PHARMACY (DEGREE)',
          content: '',
          showReadMore: true,
          readMoreLink: '/degree',
          class: 'text-center'
        },
        {
          link: '/degree',
          image: 'https://i.ibb.co/5n9WRLN/Banner1-3.png',
          title: 'The Best Learning Institution',
          text: 'YADAVRAO TASGAONKAR INSTITUTE OF PHARMACY (DIPLOMA)',
          content: '',
          showReadMore: true,
          readMoreLink: '/degree',
          class: 'text-right'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>

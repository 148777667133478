import Vue from 'vue'
import Vuex from 'vuex'
import { initializeApp } from 'firebase/app'
import { collection, getFirestore, onSnapshot, addDoc } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDJTRE7JjkdFTF_x4coprN8UK1cMk7qRlQ',
  authDomain: 'ytip-ab173.firebaseapp.com',
  projectId: 'ytip-ab173',
  storageBucket: 'ytip-ab173.appspot.com',
  messagingSenderId: '178911782860',
  appId: '1:178911782860:web:fc996663b276317db09f69',
  measurementId: 'G-SQXJV8H1XS'
}

// Initialize Firebase
initializeApp(firebaseConfig)
const firestore = getFirestore()
const studentGrievanceRef = collection(firestore, 'student-grievance')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    companies: [
      { image: 'https://i.ibb.co/PW5P9rK/Screenshot-2021-09-29-at-11-39-06-PM.png' },
      { image: 'https://i.ibb.co/CsBmJb9/Solutions-Partners-cognizant-0.png' },
      { image: 'https://i.ibb.co/C5VVvTK/02852-Merck-Logo-W-Anthem-Horizontal-Teal-Grey-RGB.png' },
      { image: 'https://i.ibb.co/N3GZdLk/LOGO.gif' },
      { image: 'https://i.ibb.co/Kbf1Jv9/influxhealthtech.png' },
      { image: 'https://i.ibb.co/mvwLkbH/Inventia-Healthcare-Private-Limited-1493809132.jpg' },
      { image: 'https://i.ibb.co/86YZVQR/TCS-Logo-1.jpg' }
    ],
    testimonials: [],
    upComingEvents: []
  },
  mutations: {
    initCompany (state) {
      state.companies = []
    },
    addCompany (state, company) {
      state.companies.push(company)
    },
    initTestimonials (state) {
      state.testimonials = []
    },
    addTestimonials (state, t) {
      state.testimonials.push(t)
    },
    initEvents (state) {
      state.upComingEvents = []
    },
    addEvents (state, event) {
      const dateArr = event.dateTime.toDate().toString().split(' ')
      const date = dateArr[2] + ' ' + dateArr[1] + ' ' + dateArr[3]
      const time = dateArr[4]
      const eventObj = {
        title: event.title,
        image: event.image,
        date: date,
        time: time
      }
      state.upComingEvents.push(eventObj)
    }
  },
  actions: {
    loadCompanies (context) {
      onSnapshot(collection(firestore, 'companies'), (snapshot) => {
        context.commit('initCompany')
        snapshot.docs.forEach((doc) => {
          context.commit('addCompany', doc.data())
        })
      })
    },
    loadTestimonials (context) {
      onSnapshot(collection(firestore, 'testimonials'), (snapshot) => {
        context.commit('initTestimonials')
        snapshot.docs.forEach((doc) => {
          context.commit('addTestimonials', doc.data())
        })
      })
    },
    loadEvents (context) {
      onSnapshot(collection(firestore, 'events'), (snapshot) => {
        context.commit('initEvents')
        snapshot.docs.forEach((doc) => {
          context.commit('addEvents', doc.data())
        })
      })
    },
    async submitFormData ({ commit }, formData) {
      try {
        console.log('studentGrievanceRef', studentGrievanceRef, formData)
        addDoc(studentGrievanceRef, formData)
        return { success: true, message: 'Form submitted successfully!' }
      } catch (error) {
        console.error('Error adding form data to Firestore:', error)
        return { success: false, message: 'There was an error submitting the form. Please try again.' }
      }
    }
  },
  modules: {
  }
})

<template>
  <div>
    <!--Contact Info Wrap Start-->
    <div class="iq_contact_info">
      <div class="container">
        <ul>
          <li>
            <i class="fa fa-map-marker"></i>
            <div class="iq_info_wrap">
              <h5>Institute Address</h5>
              <p>
                Dr. N.Y. Tasgaonkar Education Complex, Village - Chandhai, Post - Nasrapur, Bhivpuri Road, Karjat,<br>
                Dist - Raigad<span>Maharashtra 410201</span>
              </p>
            </div>
          </li>
          <li>
            <i class="fa fa-phone"></i>
            <div class="iq_info_wrap">
              <h5>Contact Number</h5>
              <span>+91 90827 22423</span>
              <span>+91 88050 12269</span>
            </div>
          </li>
          <li>
            <i class="fa fa-envelope"></i>
            <div class="iq_info_wrap">
              <h5>Email</h5>
              <a href="mailto:ytipdegree@gmail.com">ytipdegree@gmail.com</a>
              <a href="mailto:ytipdiploma@gmail.comm">ytipdiploma@gmail.com</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--Contact Info Wrap End-->
    <!--Footer Wrap Start-->
    <footer class="iq_footer_bg">
      <div class="container">
        <div class="row">
          <!--Widget About Start-->
          <div class="col-md-3 col-sm-6">
            <div class="iq_uni_title">
              <!--Widget Title Start-->
              <h4>Yadavrao Tasgaonkar <span>Institute of Pharmacy</span></h4>
              <!--Widget Title End-->
              <p></p>
              <ul class="text-white">
                <li><span>Moday - Friday</span> 09:00- 05:30</li>
                <li><span>Saturday</span> Closed</li>
                <li><span>Sunday</span> Closed</li>
              </ul>
            </div>
          </div>
          <!--Widget About End-->
          <!--Widget Archive Start-->
          <div class="col-md-3 col-sm-6">
            <div class="widget widget_archive">
              <!--Widget Title Start-->
              <h5 class="widget-title"><span>Quick</span> Links</h5>
              <!--Widget Title End-->
              <!--Social Media Start-->
              <ul>
                <li><router-link to="/about-us">About Trust</router-link></li>
                <li><router-link to="/degree">Y.T.I.P (Degree)</router-link></li>
                <li><router-link to="/diploma">Y.T.I.P (Diploma)</router-link></li>
                <li><router-link to="/admissions">Admissions</router-link></li>
                <li><router-link to="/placements">Placements</router-link></li>
                <li><router-link to="/campus-life">Campus Life</router-link></li>
                <li><router-link to="/fra">FRA</router-link></li>
              </ul>
              <!--Social Media End-->
            </div>
          </div>
          <!--Widget Archive End-->
          <!--Widget Flickr Start-->
          <div class="col-md-3 col-sm-6">
            <!--<div class="widget widget-flickr">
              &lt;!&ndash; Widget Title Start&ndash;&gt;
              <h5 class="widget-title"><span>Our</span> Photo</h5>
              &lt;!&ndash; Widget Title End&ndash;&gt;
              <div class="flickr-wrap">
                <a class="flickrbadges" href="#"><img src="extra-images/flickr1.jpg" alt=""></a>
                <a class="flickrbadges" href="#"><img src="extra-images/flickr2.jpg" alt=""></a>
                <a class="flickrbadges" href="#"><img src="extra-images/flickr3.jpg" alt=""></a>
                <a class="flickrbadges" href="#"><img src="extra-images/flickr4.jpg" alt=""></a>
                <a class="flickrbadges" href="#"><img src="extra-images/flickr5.jpg" alt=""></a>
                <a class="flickrbadges" href="#"><img src="extra-images/flickr6.jpg" alt=""></a>
                <a class="flickrbadges" href="#"><img src="extra-images/flickr4.jpg" alt=""></a>
                <a class="flickrbadges" href="#"><img src="extra-images/flickr5.jpg" alt=""></a>
                <a class="flickrbadges" href="#"><img src="extra-images/flickr6.jpg" alt=""></a>
              </div>
            </div>-->
          </div>
          <!--Widget Flickr End-->
          <!--Widget News Letter Start-->
          <div class="col-md-3 col-sm-6">
            <div class="widget iq_footer_newsletter">
              <!--Widget Title Start-->
              <h5 class="widget-title"><span>Our</span> Newsletter</h5>
              <!--Widget Title End-->
              <p></p>
              <form class="iq-input">
                <input placeholder="Search ..." type="text">
                <label class="iq-input-icon"><input type="submit"></label>
              </form>
              <!--Widget Title Start-->
              <h5 class="widget-title border-none"><span>Our</span> Connected</h5>
              <!--Widget Title Start-->
              <ul class="iq_footer_social">
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <!--Widget News Letter End-->
        </div>
      </div>
    </footer>
    <!--Footer Wrap End-->
    <!--Copy Right Start-->
    <div class="footer_strip">
      <h5>© 2021 <a target="_blank" href="https://bhosletechsol.in">BhosleTechSol</a>.
        All Rights reserved</h5>
    </div>
    <!--Copy Right End-->
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.text-white {
  color: #ffffff;
}
</style>

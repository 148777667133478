<template>
  <!--Header Wrap Start-->
  <header class="iq_header_1">
    <div class="container">
      <!--Logo Wrap Start-->
      <div class="iq_logo">
        <a href="#"><img src="images/logo.png" width="150px" alt="Iqoniq Themes"></a>
      </div>
      <!--Logo Wrap Start-->
      <!--Top Strip Wrap Start-->
      <div class="iq_ui_element">
        <!--Top Strip Wrap Start-->
        <div class="iq_top_strip">
          <div class="iq_top_contact pull-left">
            <a href="#"> Call us : +91 91122 33017</a>
            <a href="#"> Email : enquiry.ytip@tasgaonkartech.com</a>
          </div>
          <div class="iq_time_wrap pull-right text-uppercase">
            <b><router-link to="/student-grievance">Student Grievance</router-link></b>
          </div>
          <div class="iq_time_wrap pull-right text-uppercase" style="margin-left: 10px; margin-right: 10px">
            <b>|</b>
          </div>
          <div class="iq_time_wrap pull-right text-uppercase">
            <b><router-link to="/academics">Academics</router-link></b>
          </div>
        </div>
        <!--Top Strip Wrap End-->
        <!--Navigation wrap Start-->
        <div class="navigation-wrapper pull-left">
          <div class="navigation pull-left">
            <ul>
              <li><a href="/">Home</a></li>
              <li><router-link to="/about-us">About us</router-link>
                <ul class="children">
                  <li><router-link to="/about-us">About Trust</router-link></li>
                  <li><router-link to="/managing-committee">Managing Committee</router-link></li>
                  <li><router-link to="/chairman-message">Chairman Message</router-link></li>
                </ul>
              </li>
              <li><router-link to="/degree">Departments</router-link>
                <ul class="children">
                  <li><router-link to="/degree">Y.T.I.P (Degree)</router-link></li>
                  <li><router-link to="/diploma">Y.T.I.P (Diploma)</router-link></li>
                </ul>
              </li>
              <li class="menu-item mg-parent-menu"><router-link to="/faculty">Faculty</router-link></li>
              <li><router-link to="/admissions-b-pharm">Admissions</router-link>
                <ul class="children">
                  <li><router-link to="/admissions-b-pharm">Degree</router-link></li>
                  <li><router-link to="/admissions-d-pharm">Diploma</router-link></li>
                </ul>
              </li>
              <li><router-link to="/placements">Placements</router-link></li>
              <li><router-link to="/campus-life">Campus Life</router-link></li>
              <li><router-link to="/ytip-naac">NAAC</router-link></li>
              <li><router-link to="/contact-us">Contact Us</router-link></li>
            </ul>
          </div>

          <!--DL Menu Start-->
          <div id="mg-responsive-navigation" class="dl-menuwrapper">
            <button class="dl-trigger">Open Menu</button>
            <ul class="dl-menu">
              <ul>
                <li class="menu-item mg-parent-menu"><a href="/">Home</a></li>
                <li><router-link to="/about-us">About us</router-link>
                  <ul class="children">
                    <li><router-link to="/about-us">About Trust</router-link></li>
                    <li><router-link to="/managing-committee">Managing Committee</router-link></li>
                    <li><router-link to="/chairman-message">Chairman Message</router-link></li>
                  </ul>
                </li>
                <li class="menu-item mg-parent-menu"><router-link to="/faculty">Faculty</router-link></li>
                <li><a href="#">Departments</a>
                  <ul class="children">
                    <li><router-link to="/degree">Y.T.I.P (Degree)</router-link></li>
                    <li><router-link to="/diploma">Y.T.I.P (Diploma)</router-link></li>
                  </ul>
                </li>
                <li>
                  <router-link to="/admissions-b-pharm">Admissions</router-link>
                  <ul class="children">
                    <li><router-link to="/admissions-b-pharm">Degree</router-link></li>
                    <li><router-link to="/admissions-d-pharm">Diploma</router-link></li>
                  </ul>
                </li>
                <li class="menu-item mg-parent-menu"><router-link to="/placements">Placements</router-link></li>
                <li class="menu-item mg-parent-menu"><router-link to="/campus-life">Campus Life</router-link></li>
                <li class="menu-item mg-parent-menu"><router-link to="/ytip-naac">NAAC</router-link></li>
                <li class="menu-item mg-parent-menu"><router-link to="/contact-us">Contact Us</router-link></li>
                <li class="menu-item mg-parent-menu"><router-link to="/student-grievance">Student Grievance</router-link></li>
                <li class="menu-item mg-parent-menu"><router-link to="/academics">Academics</router-link></li>
              </ul>
            </ul>
          </div>
          <!--DL Menu END-->
          <!--Search Wrap Start-->
<!--          <div class="iq_search pull-right">-->
<!--            <button data-toggle="modal" data-target="#search-box"><i class="fa fa-search"></i></button>-->
<!--          </div>-->
          <!--Search Wrap End-->
        </div>
        <!--Navigation wrap End-->
      </div>
      <!--Top Strip Wrap End-->
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>

</style>

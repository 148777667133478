<template>
  <section class="iq_upcomming_bg" v-if="upComingEvents.length">
    <div class="container">
      <!--Heading Wrap Start-->
      <div class="iq_heading_1 text-center">
        <h4>Up Coming <span>Events</span></h4>
      </div>
      <!--Heading Wrap End-->
      <!--Upcoming List Wrap Start-->
      <div class="row">
        <!--UpComing Event Start-->
        <div class="col-md-3 col-sm-6" v-for="(event, index) in upComingEvents" :key="index">
          <div class="iq_upcomming_event">
            <figure>
              <img :src="event.image" alt="Image Here">
            </figure>
            <div class="iq_upcomming_des">
              <span>{{ event.date }}</span>
              <p>{{ event.time }}</p>
              <h5><a href="#">{{ event.title }}</a></h5>
            </div>
          </div>
        </div>
        <!--UpComing Event End-->
      </div>
      <!--Upcoming List Wrap End-->
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UpCommingEvents',
  data () {
    return {
      // upComingEvents: [
      //   {
      //     image: 'https://i0.wp.com/www.thestartupjournal.com/wp-content/uploads/2015/11/Top-10-Colege-Fests-India.jpg',
      //     title: 'Join us on the day of event',
      //     date: '21 aug',
      //     time: '10:00 am'
      //   }
      // ]
    }
  },
  computed: {
    ...mapState(['upComingEvents'])
  }
}
</script>

<style lang="scss" scoped>
.iq_upcomming_event {
  iframe {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
}
</style>

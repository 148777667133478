<template>
  <!--Brand Slider Start-->
  <div class="brnd-slider-wrap">
    <div class="container">
      <div class="brnd-slider">
        <div v-for="(company, index) in companies" :key="index">
          <a href="#" class="thumb">
            <img :src="company.image" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--Brand Slider End-->
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PlacementsSlide',
  computed: {
    ...mapState(['companies'])
  }
}
</script>

<style scoped>
.thumb {
  background-color: #ffffff;
}
.thumb img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
</style>

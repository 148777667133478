<template>
  <div id="app">
    <div class="iq_wrapper">
      <Header></Header>
      <router-view/>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import { mapActions } from 'vuex'

export default {
  components: {
    Header,
    Footer
  },
  methods: {
    ...mapActions(['loadCompanies', 'loadTestimonials', 'loadEvents'])
  },
  mounted () {
    // this.loadCompanies()
    // this.loadTestimonials()
    this.loadEvents()
  }
}
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<template>
  <div class="home">

    <!-- BANNERS START-->
    <banners></banners>
    <!-- BANNERS ENDS-->

    <!--Iqoniq Content Start-->
    <div class="iq_content_wrap">

      <!--We Are Professional Wrap Start-->
      <div class="we_professional_bg">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <section>
                <!--Heading Wrap Start-->
                <div class="iq_heading_1 text-left">
                  <h4>About <span>YTIP</span></h4>
                  <p>
                    Saraswati Education Society’s (SES) Yadavrao Tasgaonkar Institute of Pharmacy is established in 2005.
                  </p>
                  <p>
                    The Institute is approved by Pharmacy Council of India, All India Council for Technical
                    Education and affiliated to University of Mumbai.
                  </p>
                  <p>
                    Our beautiful campus is situated near Bhivpuri road station amidst the foots of
                    Matheran, surrounded by Sahyadri mountains and right on the banks of Ulhas River
                    with state of art modern facilities.
                  </p>
                  <p>
                    Today SES is known as a leading educational group in Maharashtra and has
                    established Institutes such as colleges Engineering, Managements, Pharmacy (Degree
                    & Diploma), Medical Sciences and Physiotherapy.
                  </p>
                </div>
                <!--Heading Wrap End-->
                <div class="row">
                  <!--Services Start-->
                  <div class="col-md-6 col-sm-6">
                    <div class="iq_professional_services">
                      <span class="icon-lecture">
                        <span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span
                        class="path5"></span><span class="path6"></span><span
                        class="path7"></span><span class="path8"></span><span
                        class="path9"></span><span class="path10"></span><span
                        class="path11"></span><span class="path12"></span><span
                        class="path13"></span><span class="path14"></span><span
                        class="path15"></span><span class="path16"></span><span
                        class="path17"></span><span class="path18"></span><span
                        class="path19"></span><span class="path20"></span><span
                        class="path21"></span><span class="path22"></span><span
                        class="path23"></span><span class="path24"></span><span
                        class="path25"></span><span class="path26"></span><span
                        class="path27"></span><span class="path28"></span><span
                        class="path29"></span><span class="path30"></span><span
                        class="path31"></span><span class="path32"></span><span
                        class="path33"></span><span class="path34"></span><span
                        class="path35"></span><span class="path36"></span><span
                        class="path37"></span><span class="path38"></span>
                      </span>
                      <h5><a href="#">Diploma</a></h5>
                      <p>
                        <b>DTE CODE-DPH3296</b> INTAKE-60 <br>
                        Recognized by P. C.I Approved by AICTE / DTE-DPH 3296, Affiliation to MSBTE
                      </p>
                    </div>
                  </div>
                  <!--Services End-->

                  <!--Services Start-->
                  <div class="col-md-6 col-sm-6">
                    <div class="iq_professional_services">
                      <span class="icon-lecture">
                      <span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span
                        class="path5"></span><span class="path6"></span><span
                        class="path7"></span><span class="path8"></span><span
                        class="path9"></span><span class="path10"></span><span
                        class="path11"></span><span class="path12"></span><span
                        class="path13"></span><span class="path14"></span><span
                        class="path15"></span><span class="path16"></span><span
                        class="path17"></span><span class="path18"></span><span
                        class="path19"></span><span class="path20"></span><span
                        class="path21"></span><span class="path22"></span><span
                        class="path23"></span><span class="path24"></span><span
                        class="path25"></span><span class="path26"></span><span
                        class="path27"></span><span class="path28"></span><span
                        class="path29"></span><span class="path30"></span><span
                        class="path31"></span><span class="path32"></span><span
                        class="path33"></span><span class="path34"></span><span
                        class="path35"></span><span class="path36"></span><span
                        class="path37"></span><span class="path38"></span>
                      </span>
                      <h5><a href="#">Degree</a></h5>
                      <p>
                        <b>DTE CODE-PH3232</b> INTAKE-100 <br>
                        Recognized by P.C.I. Approved by AICTE / DTE-PH 3232, Affiliated to University of Mumbai
                      </p>
                    </div>
                  </div>
                  <!-- Services End-->

                  <!--Services Start-->
                  <!-- <div class="col-md-6 col-sm-6">
                      <div class="iq_professional_services">
                          <span class="icon-medical-3">
                              <span class="path1"></span><span class="path2"></span><span
                                  class="path3"></span><span class="path4"></span><span
                                  class="path5"></span><span class="path6"></span><span
                                  class="path7"></span><span class="path8"></span><span
                                  class="path9"></span><span class="path10"></span><span
                                  class="path11"></span><span class="path12"></span><span
                                  class="path13"></span><span class="path14"></span><span
                                  class="path15"></span><span class="path16"></span><span
                                  class="path17"></span><span class="path18"></span><span
                                  class="path19"></span><span class="path20"></span><span
                                  class="path21"></span><span class="path22"></span><span
                                  class="path23"></span><span class="path24"></span>
                          </span>
                          <h5><a href="#">Zoology</a></h5>
                          <p>This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit
                              auctor aliquet. Aenean lorem quis bibendum auctor, nisi elit consequat
                              ipsum nec sagittis.</p>
                      </div>
                  </div> -->
                  <!--Services End-->

                  <!--Services Start-->
                  <!-- <div class="col-md-6 col-sm-6">
                      <div class="iq_professional_services">
                          <span class="icon-debate">
                              <span class="path1"></span><span class="path2"></span><span
                                  class="path3"></span><span class="path4"></span><span
                                  class="path5"></span><span class="path6"></span><span
                                  class="path7"></span><span class="path8"></span><span
                                  class="path9"></span><span class="path10"></span><span
                                  class="path11"></span><span class="path12"></span>
                          </span>
                          <h5><a href="#">Politics</a></h5>
                          <p>This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit
                              auctor aliquet. Aenean lorem quis bibendum auctor, nisi elit consequat
                              ipsum nec sagittis.</p>
                      </div>
                  </div> -->
                  <!--Services End -->
                </div>
                <!--Professional Services Wrap End-->
              </section>
            </div>
            <!--Search Wrap Start-->
            <div class="col-md-4">
              <div class="iq_search_courses">
                <h4>Enquiry Courses</h4>
                <form>
                  <!--Input Start-->
                  <div class="iq-input">
                    <input type="text" placeholder="Name">
                  </div>
                  <!--Input End-->
                  <!--Input Start-->
                  <div class="iq-input">
                    <input type="email" placeholder="E-mail">
                  </div>
                  <!--Input End-->
                  <!--Input Start-->
                  <div class="iq-input">
                    <input type="text" placeholder="Contact No.">
                  </div>
                  <!--Input End-->
                  <!--Input Start-->
                  <div class="iq-input">
                    <select name="Course">
                      <option value="0">D-Pharmacy</option>
                      <option value="1">B-Pharmacy</option>
                    </select>
                  </div>
                  <!--Input End-->
                  <!--Input Start-->
                  <div class="iq-input">
                    <input class="iq_link_1" type="submit" value="Submit">
                  </div>
                  <!--Input End-->
                </form>
              </div>
            </div>
            <!--Search Wrap End-->
          </div>
        </div>
      </div>
      <!--We Are Professional Wrap End-->

      <!--Get Best Knowledge Wrap Start-->
      <section class="iq_best_knowledge_bg" :style="{
        backgroundImage: 'url(https://i.ibb.co/nmvqzBn/20190212133024-IMG-9806-1.jpg)',
        backgroundPosition: 'center left'
      }">
        <!--Best Knowledge Description Wrap Start-->
        <div class="col-md-6 col-sm-offset-6 col-md-offset-6">
          <div class="iq_knowledge_des">
            <!--Heading Wrap Start-->
            <div class="iq_heading_1 text-left">
              <h4>OUR <span>TRUST</span> AREA</h4>
            </div>
            <!--Heading Wrap End-->
            <ul class="categries-list">
              <li><a href="">Overall development of students.</a></li>
              <li><a href="">Career advancement of the faculty.</a></li>
              <li><a href="">Value addition to the curriculum.</a></li>
              <li><a href="">Emphasis on practical training in laboratories and work shop.</a></li>
              <li><a href="">Organizing and participating in Seminars. Conference and competitions.</a></li>
              <li><a href="">Close monitoring of academic activities.</a></li>
              <li><a href="">Implementation of teachers – Guardian scheme.</a></li>
              <li><a href="">Healthy educational environment.</a></li>
            </ul>
          </div>
        </div>
        <!--Best Knowledge Description Wrap Start-->
      </section>
      <!--Get Best Knowledge Wrap End-->

      <!--Our Gallery Wrap Start-->
      <!--<gallery/>-->
      <!--Our Gallery Wrap End-->

      <!--UpComing Event Wrap Start-->
      <up-comming-events/>
      <!--UpComing Event Wrap End-->

      <!--Our Blog Slider Start-->
<!--      <blogs/>-->
      <!--Our Blog Slider End-->

      <!--Testimonial Slider Wrap Start-->
      <testimonial/>
      <!--Testimonial Slider Wrap End-->

    </div>
    <!--Iqoniq Content End-->

    <!-- COMPANY LOGO SLIDES START-->
    <placements-slide/>
    <!-- COMPANY LOGO SLIDES START-->

  </div>
</template>

<script>
import banner1 from '../assets/images/banner-1.jpg'
import banner2 from '../assets/images/banner-2.jpg'
import banner3 from '../assets/images/banner-3.jpg'
import PlacementsSlide from '../components/PlacementsSlide'
import Banners from '../components/Banners'
import UpCommingEvents from '../components/UpCommingEvents'
import Testimonial from '../components/Testimonial'

export default {
  name: 'Home',
  components: { Testimonial, UpCommingEvents, Banners, PlacementsSlide },
  data () {
    return {
      banners: [
        {
          link: '/about-us',
          image: banner1,
          title: 'The Best Learning Institution',
          text: 'Welcome to Yadavrao Tasgaonkar Institute of Pharmacy',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing  elit',
          showReadMore: true,
          readMoreLink: '/about-us',
          class: 'text-left'
        },
        {
          link: '/degree',
          image: banner2,
          title: 'The Best Learning Institution',
          text: 'YADAVRAO TASGAONKAR INSTITUTE OF PHARMACY (DEGREE)',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing  elit',
          showReadMore: true,
          readMoreLink: '/degree',
          class: 'text-center'
        },
        {
          link: '/degree',
          image: banner3,
          title: 'The Best Learning Institution',
          text: 'YADAVRAO TASGAONKAR INSTITUTE OF PHARMACY (DIPLOMA)',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing  elit',
          showReadMore: true,
          readMoreLink: '/degree',
          class: 'text-right'
        }
      ]
    }
  }
}
</script>

<style>
/*.iq-caption-title, .iq-caption-text, .iq-caption-contant {*/
/*  background: #000000a3;*/
/*}*/
</style>
